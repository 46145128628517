<template>
  <div>
    <div class="p-4">
      <div class="vx-row BgWhiteBS titlesBar mb-4">
        <div class="vx-col flex justify-center md:w-1/12 sm:w-1/12 w-1/2">
          No
        </div>
        <div
          class="vx-col flex justify-center md:w-3/12 sm:w-3/12 sm:block hidden"
        >
          Created
        </div>
        <div class="vx-col flex justify-center md:w-2/12 sm:w-2/12 w-1/2">
          Total Gold Gr ({{ selectedGoldWeightTypeStore.weightType }})
        </div>
        <div class="vx-col flex justify-center md:w-3/12 sm:w-3/12 w-1/2">
          <p class="p-0 m-0 sm:block hidden">Total Resin/Credit</p>
          <p class="p-0 m-0 sm:hidden text-center">
            Total
            <br />
            Resin/Credit
          </p>
        </div>
        <div
          class="vx-col flex justify-center items-center md:w-2/12 sm:w-3/12 w-1/2"
        >
          Status
        </div>
      </div>

      <div
        style="cursor: pointer"
        @click="OpenDetailPage(order.id)"
        class="vx-row BgWhiteBS mt-2 orderList items-center"
        v-for="order in orders"
        :key="order.id"
      >
        <div class="vx-col flex justify-center md:w-1/12 sm:w-1/12 w-1/2">
          {{ order.id }}
        </div>
        <div
          class="vx-col flex justify-center md:w-3/12 sm:w-3/12 w-1/2 sm:block hidden"
        >
          {{ GetRelativeTime(order.created) }}
        </div>
        <div class="vx-col flex justify-center md:w-2/12 sm:w-2/12 w-1/2">
          {{
            (order.total_volume * selectedGoldWeightTypeStore.value).toFixed(2)
          }}
        </div>
        <div class="vx-col flex justify-center md:w-3/12 sm:w-3/12 w-1/2">
          {{ order.total_weight }}
        </div>
        <div class="vx-col flex justify-center md:w-2/12 sm:w-3/12 w-1/2">
          <vs-chip :color="GetOrderStatusColor(order.order_status)">{{
            order.order_status_display
          }}</vs-chip>
        </div>
      </div>
    </div>

    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="HandleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";

export default {
  data() {
    return {
      orders: [],
      //Pagination
      currentPage: 1,
      totalPage: 1,
      checkStatusRequest: false,
    };
  },
  methods: {
    HandleChangePage(currentPage, intervalActive = false) {
      if (!intervalActive) {
        this.currentPage = currentPage;
      }
      this.GetOrders();
    },

    OpenDetailPage(id) {
      this.$router
        .push({
          name: "nokta_order_detail",
          query: { id: id },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    GetOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
    GetRelativeTime(value) {
      return moment(moment(String(value)), "YYYYMMDD").fromNow();
    },
    GetOrders() {
      let params = {};
      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }
      if (!this.checkStatusRequest) {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      }

      Api.get(
        API.BASEURL + API.NOKTA_MARKET_ORDERS,
        this.HandleGetOrders,
        params
      );
    },
    HandleGetOrders(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.orders = data.results;
        if (this.currentPage == 1) {
          this.totalPage = Math.ceil(data.count / data.results.length);
        }
      }
    },
  },
  computed: {
    selectedGoldWeightTypeStore() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
  },
  created() {
    setInterval(() => {
      this.checkStatusRequest = true;
      this.HandleChangePage(0, true);
    }, 5000);
  },
};
</script>

<style scoped>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.titlesBar {
  font-size: 1.1rem;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}
.orderList {
  padding: 5px;
}
</style>
